import React from "react"
import { get } from "lodash"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useTheme } from "v2/Lib/mui"

library.add(fas)
// library.add(fat)
// library.add(fad)
library.add(far)
// library.add(fal)

export default function Icon (props) {
  const {
    icon,

    color,
    primaryColor,
    primaryOpacity,
    secondaryColor,
    secondaryOpacity,
    style,
    iconType = "regular",

    ...otherProps
  } = props

  const theme = useTheme()
  const iconStyle = { ...style }
  if (color) {
    iconStyle.color = get(theme, `palette.${color}.main`, color)
  }
  if (primaryColor) {
    iconStyle["--fa-primary-color"] = get(theme, `palette.${primaryColor}.main`, primaryColor)
  }
  if (primaryOpacity != null) {
    iconStyle["--fa-primary-opacity"] = primaryOpacity
  }
  if (secondaryColor) {
    iconStyle["--fa-secondary-color"] = get(theme, `palette.${secondaryColor}.main`, secondaryColor)
  }
  if (secondaryOpacity != null) {
    iconStyle["--fa-secondary-opacity"] = secondaryOpacity
  }

  if (Array.isArray(icon)) {
    const icons = icon.map(itemIcon => itemIcon.icon ? itemIcon : ({ icon: itemIcon.icon }))
    return (
      <span className="fa-layers fa-fw">
        {icons.map(({ icon: itemIcon, ...itemProps }) => (
          <Icon
            key={itemIcon}
            {...props}
            icon={itemIcon}
            {...itemProps}
          />
        ))}
      </span>
    )
  }

  return (
    <FontAwesomeIcon
      style={iconStyle}
      // icon={`fa-solid fa-${icon}`}
      // icon={`fa-thin fa-${icon}`}
      // icon={`fa-duotone fa-${icon}`}
      // icon={`fa-light fa-${icon}`}
      // icon={`fa-regular fa-${icon}`}
      icon={`fa-${iconType} fa-${icon}`}
      {...otherProps}
    />
  )
}
