import React from "react"

import { LicenseInfo } from "@mui/x-license-pro"
import Icon from "v2/Lib/fontAwesome"

/* overrides */
import MuiButton from "@mui/material/Button"
import MuiIconButton from "@mui/material/IconButton"
import MuiTextField from "@mui/material/TextField"
import MuiCheckbox from "@mui/material/Checkbox"
import MuiSlider from "@mui/material/Slider"
import MuiAutocomplete from "@mui/material/Autocomplete"
import MuiNativeSelect from "@mui/material/NativeSelect"
import MuiTab from "@mui/material/Tab"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiTooltip from "@mui/material/Tooltip"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker"
import { DateTimeField as MuiDateTimeField } from "@mui/x-date-pickers/DateTimeField"
import { SingleInputDateTimeRangeField as MuiSingleInputDateTimeRangeField } from "@mui/x-date-pickers-pro/SingleInputDateTimeRangeField"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import Menu from "@mui/material/Menu"
import MuiMenuItem from "@mui/material/MenuItem"
import Popper from "@mui/material/Popper"
import Badge from "@mui/material/Badge"
import MuiToggleButton from "@mui/material/ToggleButton"
import ButtonGroup from "@mui/material/ButtonGroup"

/* simple export */
export { makeStyles } from "@mui/styles"
export { StyledEngineProvider, useTheme } from "@mui/material/styles"
export { default as ThemeProvider } from "@mui/material/styles/ThemeProvider"
export {
  LocalizationProvider,
  huHU as pickerLocaleHU,
} from "@mui/x-date-pickers-pro"

export { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField"
export { colors } from "@mui/material"
export { default as Dialog } from "@mui/material/Dialog"
export { default as DialogTitle } from "@mui/material/DialogTitle"
export { default as DialogContent } from "@mui/material/DialogContent"
export { default as DialogActions } from "@mui/material/DialogActions"
export { default as DialogContentText } from "@mui/material/DialogContentText"
export { default as CssBaseline } from "@mui/material/CssBaseline"
export { default as GlobalStyles } from "@mui/material/GlobalStyles"
export { default as CircularProgress } from "@mui/material/CircularProgress"
export { default as Avatar } from "@mui/material/Avatar"
export { default as Breadcrumbs } from "@mui/material/Breadcrumbs"
export { default as AppBar } from "@mui/material/AppBar"
export { default as Toolbar } from "@mui/material/Toolbar"
export { default as Tabs } from "@mui/material/Tabs"
export { default as CardActionArea } from "@mui/material/CardActionArea"
export { default as Card } from "@mui/material/Card"
export { default as CardMedia } from "@mui/material/CardMedia"
export { default as CardContent } from "@mui/material/CardContent"
export { default as CardHeader } from "@mui/material/CardHeader"
export { default as Collapse } from "@mui/material/Collapse"
export { default as Table } from "@mui/material/Table"
export { default as TableHead } from "@mui/material/TableHead"
export { default as TableRow } from "@mui/material/TableRow"
export { default as TableCell } from "@mui/material/TableCell"
export { default as TableBody } from "@mui/material/TableBody"
export { default as FormHelperText } from "@mui/material/FormHelperText"
export { default as InputAdornment } from "@mui/material/InputAdornment"
export { default as Stepper } from "@mui/material/Stepper"
export { default as Step } from "@mui/material/Step"
export { default as StepLabel } from "@mui/material/StepLabel"
export { default as StepContent } from "@mui/material/StepContent"
export { default as Link } from "@mui/material/Link"
export { default as Pagination } from "@mui/material/Pagination"
export { default as TablePagination } from "@mui/material/TablePagination"
export { default as ToggleButtonGroup } from "@mui/material/ToggleButtonGroup"
export { default as Alert } from "@mui/material/Alert"
export { default as LinearProgress } from "@mui/material/LinearProgress"
export { default as List } from "@mui/material/List"
export { default as ListItem } from "@mui/material/ListItem"
export { default as ListItemButton } from "@mui/material/ListItemButton"
export { default as ListItemText } from "@mui/material/ListItemText"
export { default as ListItemIcon } from "@mui/material/ListItemIcon"
export { default as ListSubheader } from "@mui/material/ListSubheader"
export { default as Snackbar } from "@mui/material/Snackbar"
export { default as SnackbarContent } from "@mui/material/SnackbarContent"
export { default as Slide } from "@mui/material/Slide"
export { default as Popover } from "@mui/material/Popover"
export { default as Grid } from "@mui/material/Grid"
export { default as Paper } from "@mui/material/Paper"
export { default as Divider } from "@mui/material/Divider"
export { default as useMediaQuery } from "@mui/material/useMediaQuery"
export { default as ListItemSecondaryAction } from "@mui/material/ListItemSecondaryAction"
export { default as Drawer } from "@mui/material/Drawer"
export { default as SwipeableDrawer } from "@mui/material/SwipeableDrawer"
export { default as ImageListItem } from "@mui/material/ImageListItem"
export { default as ImageList } from "@mui/material/ImageList"
export { default as AccordionDetails } from "@mui/material/AccordionDetails"
export { default as Container } from "@mui/material/Container"
export { default as FormControlLabel } from "@mui/material/FormControlLabel"
export { default as Stack } from "@mui/material/Stack"
export { default as Select } from "@mui/material/Select"
export { default as Grow } from "@mui/material/Grow"
export { default as RadioGroup } from "@mui/material/RadioGroup"
export { default as Radio } from "@mui/material/Radio"
export { default as Switch } from "@mui/material/Switch"
export { default as Skeleton } from "@mui/material/Skeleton"
export {
  Box, Chip, Typography, FormLabel, FormControl, FormGroup, Menu, Popper, MuiTooltip as Tooltip, Badge, ButtonGroup,
}

export {
  DataGrid,
  GridToolbarQuickFilter,
  useGridApiContext,
  GridCellCheckboxRenderer,
  useGridSelector,
  GridRow,
  GridColumnMenu,
  GridCell,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  huHU as gridLocaleHU,
} from "@mui/x-data-grid"
export {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  gridRowCountSelector,
  gridPaginationModelSelector,
  gridRowSelectionStateSelector,
  gridSortModelSelector,
  gridFilterModelSelector,
  GridColumnMenuPinningItem,
} from "@mui/x-data-grid-pro"

LicenseInfo.setLicenseKey("26666b597611135eb50ffd64dd2a63b0Tz03NzA5NCxFPTE3Mjk0NzA4MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=")

function useMergedObject (obj1, obj2) {
  return React.useMemo(() => {
    return {
      ...obj1,
      ...obj2,
    }
  }, [obj1, obj2])
}

export const Button = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Button components")
  return (
    <MuiButton {...props} ref={ref} />
  )
})
export const ToggleButton = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all ToggleButton components")
  if (!props.title && !props.noTitle) throw new Error(`"title" must be provided for all ToggleButton components (${props.id})`)
  const {
    title,
    noTitle,
    titleProps,
    TooltipComponent = MuiTooltip,
    ...buttonProps
  } = props
  return (
    <TooltipComponent title={title || ""} {...titleProps}>
      <span>
        <MuiToggleButton value={props.id} {...buttonProps} ref={ref} />
      </span>
    </TooltipComponent>
  )
})
export const Tab = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Tab components")
  return (
    <MuiTab {...props} ref={ref} />
  )
})
export const Accordion = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Accordion components")
  return (
    <MuiAccordion {...props} ref={ref} />
  )
})
export const AccordionSummary = React.forwardRef((props, ref) => {
  return (
    <MuiAccordionSummary expandIcon={<Icon icon="caret-down" />} {...props} ref={ref} />
  )
})

const lazyTooltipWrapperDivStyle = { display: "contents" }
export const LazyTooltip = ({ children, disableMaxWidth, slotProps, ...rest }) => {
  const [renderTooltip, setRenderTooltip] = React.useState(false)

  const tootltipSlotProps = React.useMemo(() => disableMaxWidth ? ({
    ...slotProps,
    tooltip: {
      ...slotProps?.tooltip,
      sx: { maxWidth: "none", ...slotProps?.tooltip?.sx },
    },
  }) : slotProps, [slotProps, disableMaxWidth])

  return (
    <div
      onMouseEnter={() => !renderTooltip && setRenderTooltip(true)}
      style={lazyTooltipWrapperDivStyle}
    >
      {renderTooltip
        ? (
          <MuiTooltip {...rest} slotProps={tootltipSlotProps}>
            {children}
          </MuiTooltip>
          )
        : children}
    </div>
  )
}
export const IconButton = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error('"id" must be provided for all IconButton components')
  if (!props.title && !props.noTitle) throw new Error(`"title" must be provided for all IconButton components (${props.id})`)
  if (!props.icon) throw new Error(`"icon" must be provided for all IconButton components (${props.id})`)
  const {
    title,
    icon,
    titleProps,
    iconProps,
    wrapperDivProps,
    noTitle,
    TooltipComponent = MuiTooltip,
    badgeProps,
    BadgeComponent = badgeProps ? Badge : React.Fragment,
    wrapperComponent: WrapperComponent = "div",
    ...buttonProps
  } = props
  return (
    <WrapperComponent
      {...wrapperDivProps}
      onClick={React.useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
      }, [])}
    >
      <TooltipComponent title={title || ""} {...titleProps}>
        <span>
          <MuiIconButton {...buttonProps} ref={ref}>
            <BadgeComponent {...badgeProps}>
              {typeof icon === "string"
                ? (
                  <Icon icon={icon} {...iconProps} />
                  )
                : icon}
            </BadgeComponent>
          </MuiIconButton>
        </span>
      </TooltipComponent>
    </WrapperComponent>
  )
})

/**
 * @param {object} props
 * @param {React.Component} props.ButtonComponent
 * @param {React.Component} props.MenuComponent
 * @param {object} props.menuProps
 * @param {function|>object[]} props.menuItems
 *
 * @returns {React.Component}
 *
 * @example <caption>Basic Usage</caption>
 * <ButtonMenu ButtonComponent={IconButton} icon="menu" menuItems={[...]} />
 * @example <caption>Menu items with onClose as callback</caption>
 * <ButtonMenu ButtonComponent={IconButton} icon="menu" menuItems={(onClose) => [...]} />
 */
export const ButtonMenu = React.forwardRef((props, ref) => {
  const {
    ButtonComponent,
    MenuComponent = Menu,
    menuProps,
    menuItems,
    children,
    onClose,
    onOpen,
    ...buttonProps
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClose = React.useCallback((e) => {
    e?.preventDefault()
    e?.stopPropagation()
    setAnchorEl(null)
    if (onClose) {
      onClose(e)
    }
  }, [onClose])
  const handleClick = React.useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      document.activeElement?.blur()
      setAnchorEl(e.currentTarget)
      if (onOpen) {
        onOpen(e)
      }
    },
    [],
  )
  const menuListProps = React.useMemo(() => ({
    "aria-labelledby": buttonProps.id,
  }), [buttonProps.id])
  const anchorOrigin = React.useMemo(() => ({
    vertical: "bottom",
    horizontal: "right",
  }), [])
  const transformOrigin = React.useMemo(() => ({
    vertical: "top",
    horizontal: "right",
  }), [])
  return (
    <>
      <ButtonComponent
        onClick={handleClick}
        disabled={menuItems?.length === 0}
        {...buttonProps}
        ref={ref}
      >
        {typeof children === "function" ? children(open) : children}
      </ButtonComponent>
      <MenuComponent
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={menuListProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...menuProps}
      >
        {typeof menuItems === "function" ? menuItems(handleClose) : menuItems}
      </MenuComponent>
    </>
  )
})

/**
 * @param {object} props
 * @param {React.Component} props.ButtonComponent
 * @param {React.Component} props.MenuComponent
 * @param {object} props.menuProps
 * @param {function|>object[]} props.menuItems
 *
 * @returns {React.Component}
 *
 * @example <caption>Menu items with onClose as callback</caption>
 * <SplitButton menuItems={(onClose) => [...]} />
 */
export const SplitButton = React.forwardRef((props, ref) => {
  const {
    ButtonComponent = Button,
    MenuComponent = Menu,
    menuProps,
    menuItems,
    children,
    onClose,
    variant = "contained",
    ...buttonProps
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClose = React.useCallback((e) => {
    e?.preventDefault()
    e?.stopPropagation()
    setAnchorEl(null)
    if (onClose) {
      onClose(e)
    }
  }, [onClose])
  const handleClick = React.useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      document.activeElement?.blur()
      setAnchorEl(e.currentTarget)
    },
    [],
  )
  const iconSx = React.useMemo(() => ({
    transform: open ? "rotate(180deg)" : null,
    transition: "transform 162ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  }), [open])
  const menuListProps = React.useMemo(() => ({
    "aria-labelledby": buttonProps.id,
  }), [buttonProps.id])
  const anchorOrigin = React.useMemo(() => ({
    vertical: "top",
    horizontal: "right",
  }), [])
  const transformOrigin = React.useMemo(() => ({
    vertical: "bottom",
    horizontal: "right",
  }), [])
  return (
    <>
      <ButtonGroup variant={variant}>
        <ButtonComponent
          {...buttonProps}
          ref={ref}
        >
          {typeof children === "function" ? children(open) : children}
        </ButtonComponent>
        <MuiButton
          size="small"
          onClick={handleClick}
        >
          <Icon icon="caret-down" sx={iconSx} />
        </MuiButton>
      </ButtonGroup>
      <MenuComponent
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={menuListProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...menuProps}
      >
        {typeof menuItems === "function" ? menuItems(handleClose) : menuItems}
      </MenuComponent>
    </>
  )
})

export const TextField = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all TextField components")
  return (
    <MuiTextField fullWidth variant="standard" autoComplete="off" {...props} ref={ref} />
  )
})

export const MenuItem = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all MenuItem components")
  return (
    <MuiMenuItem {...props} ref={ref} />
  )
})

export const Checkbox = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Checkbox components")
  return (
    <MuiCheckbox variant="standard" {...props} ref={ref} />
  )
})

export const Input = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Input components")
  return (
    <MuiTextField variant="standard" {...props} ref={ref} />
  )
})

export const Slider = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Slider components")
  return (
    <MuiSlider {...props} ref={ref} />
  )
})

const autoWidthPopperStyle = { minWidth: "fit-content" }
function AutoWidthPopper (props) {
  const style = useMergedObject(props.style, autoWidthPopperStyle)
  return (
    <Popper
      {...props}
      style={style}
      placement="bottom-start"
    />
  )
}

export function AutocompleteInput (props) {
  const { label, variant, InputProps, InputLabelProps, params } = props
  const { InputProps: inputParams, InputLabelProps: inputLabelParams, ...rest } = params
  const inputProps = useMergedObject(inputParams, InputProps)
  const inputLabelProps = useMergedObject(inputLabelParams, InputLabelProps)
  return (
    <MuiTextField
      fullWidth
      label={label}
      variant={variant}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      {...rest}
    />
  )
}
export const Autocomplete = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all Autocomplete components")
  const {
    label,
    InputLabelProps,
    InputProps,
    variant = "standard",
    ...rest
  } = props
  return (
    <MuiAutocomplete
      autoHighlight
      fullWidth
      PopperComponent={AutoWidthPopper}
      noOptionsText={window.django.pgettext("autocomplete select", "No options")}
      loadingText={window.django.pgettext("autocomplete select", "Loading...")}
      openText={window.django.pgettext("autocomplete select", "Open")}
      closeText={window.django.pgettext("autocomplete select", "Close")}
      clearText={window.django.pgettext("autocomplete select", "Clear")}
      renderInput={React.useCallback((params) => (
        <AutocompleteInput
          label={label}
          variant={variant}
          params={params}
          InputProps={InputProps}
          InputLabelProps={InputLabelProps}
        />
      ), [label, InputLabelProps, InputProps, variant])}
      disableCloseOnSelect={Boolean(rest.multiple)}
      {...rest}
      ref={ref}
    />
  )
})
export const NativeSelect = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all NativeSelect components")
  const {
    options,
    ...rest
  } = props
  return (
    <MuiNativeSelect {...rest}>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </MuiNativeSelect>
  )
})

const datePicker = { textField: TextField }
export const DatePicker = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all DatePicker components")

  const slots = useMergedObject(datePicker, props.slots)

  return (
    <MobileDatePicker
      {...props}
      slots={slots}
      ref={ref}
    />
  )
})
export const DateTimePicker = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all DateTimePicker components")

  return (
    <MobileDateTimePicker
      renderInput={React.useCallback((params) => (
        <TextField id={`${props.id}-input`} {...params} />
      ), [props.id])}
      {...props}
      ref={ref}
    />
  )
})

const dateRangePicker = { textField: TextField }
export const DateRangePicker = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all DateRangePicker components")

  const slots = useMergedObject(dateRangePicker, props.slots)

  return (
    <MobileDateRangePicker
      {...props}
      slots={slots}
      ref={ref}
    />
  )
})

const dateTimeFieldSlots = { textField: TextField }
export const DateTimeField = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all MuiDateTimeField components")

  const slots = useMergedObject(dateTimeFieldSlots, props.slots)

  return (
    <MuiDateTimeField
      {...props}
      slots={slots}
      ref={ref}
    />
  )
})

const singleInputDateTimeRangeFieldSlots = { textField: TextField }
export const SingleInputDateTimeRangeField = React.forwardRef((props, ref) => {
  if (!props.id) throw new Error("ID must be provided for all MuiSingleInputDateTimeRangeField components")

  const slots = useMergedObject(singleInputDateTimeRangeFieldSlots, props.slots)

  return (
    <MuiSingleInputDateTimeRangeField
      {...props}
      slots={slots}
      ref={ref}
    />
  )
})
export const TabPanel = React.forwardRef((props, ref) => {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      ref={ref}
      // id={`simple-tabpanel-${index}`}
      // aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
})
