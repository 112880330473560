import React from "react"

import Lottie from "v2/Lib/lottie"
import * as Loader from "v2/Assets/lottie-cloud-animation.json"
import { LOCAL_STORAGE_THEME_MODE_KEY } from "v2/Components/ThemeMode"

const LOCAL_STORAGE_REFRESH_KEY = "page_load_refresh_key"
const refreshKeySet = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_KEY)

export default function PageLoad () {
  /* NOTE not using any theme or mui stuff, because it is used in index.js before anything is initialized */
  const darkMode = window.localStorage.getItem(LOCAL_STORAGE_THEME_MODE_KEY) === "dark"
  const [showButton, setShowButton] = React.useState(false)

  const hardReloadCache = React.useCallback(() => {
    window.localStorage.removeItem("pointOfSaleState")
    window.localStorage.removeItem("pointOfSaleTransactions")
    window.localStorage.removeItem("clouderp-api-cache")
    window.location.reload(true)
  }, [])

  React.useEffect(() => {
    if (refreshKeySet) {
      window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_KEY)
    }
    const timer = setTimeout(() => {
      if (!refreshKeySet) {
        window.localStorage.setItem(LOCAL_STORAGE_REFRESH_KEY, "true")
        window.location.reload()
      } else {
        setShowButton(true)
      }
    }, 20000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div style={{
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      background: darkMode ? "#121212" : undefined,
    }}
    >
      <div style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        height: "10%",
        flexDirection: "column",
      }}
      >
        <Lottie animationData={Loader} />
        <div style={{
          textAlign: "center",
          fontWeight: 500,
          fontSize: "1rem",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
          color: darkMode ? "white" : undefined,
        }}
        >
          Cloud
          <strong>ERP</strong>
        </div>
      </div>
      {showButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: "20px",
            color: darkMode ? "white" : undefined,
          }}
        >
          <h2>Túl sokáig látod ezt a képernyőt?</h2>
          <h4>
            Lehet a böngésződ nem a legfrissebb verziót próbálja betölteni.
          </h4>
          <span
            style={{
              cursor: "pointer",
              backgroundColor: "#0767ab",
              borderColor: "#0767ab",
              color: "white",
              padding: "6px 16px",
              marginBottom: "16px",
              borderRadius: "4px",
            }}
            onClick={hardReloadCache}
          >
            Kattints ide az újratöltéshez
          </span>
          <h5>
            Ha a hiba továbbra is fenn marad, az <a style={{ color: darkMode ? "white" : undefined }} target="_blank" href="https://help.clouderp.hu/hc/hu-hu/articles/12764197489053-Mi-az-a-gyors%C3%ADt%C3%B3t%C3%A1r-a-b%C3%B6ng%C3%A9sz%C5%91ben-%C3%A9s-hogyan-lehet-t%C3%B6r%C3%B6lni-" rel="noreferrer">alábbi linken</a> találsz segítséget a böngésző adatok törléséhez
          </h5>
        </div>
      )}
    </div>
  )
}
