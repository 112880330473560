import { createTheme as muiCreateTheme } from "@mui/material/styles"
import {
  blue, green, grey, orange, red,
} from "@mui/material/colors"
import { backdropClasses } from "@mui/material"

const createGlobalStyle = (mode) => {
  const darkMode = mode === "dark"
  if (darkMode) {
    return {
      "*::-webkit-scrollbar-track": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        backgroundColor: "#121212",
      },
      "*::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
        backgroundColor: "#0b5b79",
      },
      "*::-webkit-scrollbar-thumb": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
        backgroundColor: "#0e2844",
      },
    }
  }
  return {
    body: {
      background: "#f7f7f7",
    },
    "*::-webkit-scrollbar-track": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#F5F5F5",
    },
    "*::-webkit-scrollbar": {
      width: "7px",
      height: "7px",
      backgroundColor: "#F5F5F5",
      borderRadius: "4px",
    },
    "*::-webkit-scrollbar-thumb": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: "#3885bb",
    },
  }
}

const createTheme = (mode) => {
  const darkMode = mode === "dark"
  return muiCreateTheme({
    darkMode,
    color: ([light, dark]) => (darkMode ? dark : light),
    typography: {
      useNextVariants: true,
    },
    palette: {
      mode,
      primary: {
        dark: darkMode ? "#0767ab" : "#0e2844",
        pastel: "#0b5b79",
        main: darkMode ? "#3885bb" : "#0767ab",
        light: "#3885bb",
        contrastText: "rgb(255, 255, 255)",
      },
      secondary: {
        dark: darkMode ? "#0767ab" : "#0e2844",
        pastel: "#0b5b79",
        main: darkMode ? "#3885bb" : "#0767ab",
        light: "#3885bb",
        contrastText: "rgb(255, 255, 255)",
      },
      info: {
        dark: blue[600],
        pastel: blue[300],
        main: "#2162ce",
        light: blue[100],
        contrastText: "rgb(255, 255, 255)",
      },
      success: {
        dark: green[600],
        pastel: green[300],
        main: "#37833b",
        light: green[100],
        contrastText: "rgb(255, 255, 255)",
      },
      warning: {
        dark: orange[600],
        pastel: orange[300],
        main: "#c66800",
        light: orange[100],
        contrastText: "rgb(255, 255, 255)",
      },
      error: {
        dark: "#a52c55",
        pastel: red[300],
        main: "#c23564",
        light: red[100],
        contrastText: "rgb(255, 255, 255)",
      },
      neutral: {
        dark: grey[600],
        pastel: grey[300],
        main: "#474956",
        light: grey[100],
        contrastText: "rgb(255, 255, 255)",
      },
    },
    components: {
      MUIDataTable: {
        styleOverrides: {
          caption: {
            display: "none",
          },
          responsiveBase: {
            height: "fit-content!important",
            width: "100%",
            overflow: "auto",
          },
          paper: {
            "@media (max-width:600px)": {
              boxShadow: "none",
            },
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            display: "flex !important",
            position: "sticky",
            top: 0,
            zIndex: 9,
            background: darkMode ? "black" : "white",
            height: "64px",
            "@media (max-width:600px)": {
              flexDirection: "column-reverse",
              height: "unset",
            },
          },
          filterPaper: {
            width: 500,
          },
          actions: {
            flex: "0 !important",
            minWidth: "fit-content",
          },
          // NOTE: needed because of table loader bug when filter opened
          left: {
            color: "#0767ab",
            "& > div": {
              display: "none",
            },
            "& > h1": {
              fontSize: "22px",
            },
          },
        },
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            "&>div:first-child": {
              display: "none",
            },
            position: "sticky",
            top: 0,
            height: "64px",
            "@media (max-width:600px)": {
              height: "unset",
            },
          },
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: "hidden",
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          main: {
            display: "flex !important",
            maxWidth: 400,
            "@media (max-width:600px)": {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },
          searchIcon: {
            "@media (max-width:960px)": {
              color: "white",
              width: 0,
              marginRight: 0,
            },
            "@media (max-width:600px)": {
              marginLeft: 35,
            },
          },
          searchText: {
            "@media (max-width:600px)": {
              width: 224,
            },
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: darkMode ? "black" : grey[100],
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            padding: 8,
            minWidth: "unset",
            whiteSpace: "nowrap",
            "@media (max-width:600px)": {
              minWidth: "auto",
            },
            "& p": {
              whiteSpace: "nowrap",
            },
            "@media (max-width:960px)": {
              minWidth: "auto",
              padding: "4px !important",
            },
            "@media (min-width:960px)": {
              margin: -8,
            },
          },
          footer: {
            float: "left",
            padding: "0 !important",
            marginLeft: -8,
          },
          head: {
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 1,
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            "@media (max-width:600px)": {
              minWidth: "auto",
            },
            "& span": {
              whiteSpace: "nowrap",
            },
          },
        },
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      MUIDataTableRow: {
        styleOverrides: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#e8e8e8",
            },
          },
        },
      },
      MUIDataTablePagination: {
        styleOverrides: {
          actions: {
            "@media (max-width:600px)": {
              marginLeft: "0 !important",
            },
          },
          toolbar: {
            "& > p:first-of-type": {
              "@media (max-width:600px)": {
                display: "none",
              },
            },
          },
          navContainer: {
            float: "left",
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]: {
              pointerEvents: "none",
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            '&[style*="opacity: 0"]': {
              pointerEvents: "none",
            },
          },
        },
      },
    },
  })
}

export { createGlobalStyle, createTheme }
