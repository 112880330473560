let userData;

try {
  userData = JSON.parse(window.localStorage.getItem('userData'));
} catch (err) {

}

export default {
  ready: false,

  init() {
    if (this.getEnabled()) {
      this.setup();
    }
  },

  getEnabled() {
    return (
      process.env.NODE_ENV === 'production'
    );
  },

  updateUser(newUserData) {
    try {
      window.Beamer?.update({
        user_email: newUserData?.email,
        user_firstname: newUserData?.organization?.name && `[${newUserData.organization.name}]`,
        user_lastname: newUserData?.name,
      });
    } catch (err) {
      console.error(err);
    }
  },

  setup() {
    try {
      // eslint-disable-next-line func-names
      (function (b, e, a, m) {
        const f = b.getElementsByTagName(e)[0];
        const s = b.createElement(e);
        s.async = true;
        s.src = a;
        window.beamer_config = {
          product_id: m,
          user_email: userData?.email,
          user_firstname: userData?.organization?.name && `[${userData.organization.name}]`,
          user_lastname: userData?.name,
        };
        f.parentNode.insertBefore(s, f);
      }(document, 'script', 'https://app.getbeamer.com/js/beamer-embed.js', 'eCNrICSm56088'));
      this.ready = true;
    } catch (err) {
      console.error(err);
    }
  },
};
