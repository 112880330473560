import * as Sentry from "@sentry/browser"
import Cookies from "js-cookie"

export default {
  Sentry,
  enabled: process.env.NODE_ENV === "production",
  async init () {
    if (!this.enabled) return

    let config = window.localStorage.getItem("clouderp-sentry")
    if (config) config = JSON.parse(config)
    if (!config || config.release !== Cookies.get("app_version")) {
      config = await this.fetchInitData()
    }

    if (!config) return
    window.localStorage.setItem("clouderp-sentry", JSON.stringify(config))

    Sentry.init({
      ...config,
      autoSessionTracking: false,
      sendClientReports: false,
      beforeSend (event, hint) {
        const error = hint.originalException
        if (
          error &&
          error.message &&
          (
            error.message.match(/Loading chunk [\d]+ failed/) ||
            error.message.match(/Unexpected token '<'/)
          )
        ) {
          return null
        }
        return event
      },
    })
  },

  async fetchInitData () {
    try {
      const response = await fetch("/release/")
      const releaseData = await response.json()
      const {
        sentry_dsn: dsn,
        env: environment,
        version: release,
      } = releaseData
      return {
        dsn,
        environment,
        release,
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return undefined
  },

  setUser (data) {
    Sentry.setUser(data)
  },

  setOrganization (data) {
    Sentry.setUser(data)
  },

  report (error, extra) {
    Sentry.captureException(error, {
      ...extra,
      organization_cookie: Cookies.get("organization"),
      app_version_cookie: Cookies.get("app_version"),
    })
  },
}

export const setSentryUser = (data) => {
  Sentry.configureScope((scope) => {
    scope.setUser(data)
  })
}
