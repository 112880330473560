let userData;

try {
  userData = JSON.parse(window.localStorage.getItem('userData'));
} catch (err) {

}

export default {
  ready: false,

  init() {
    if (this.getEnabled()) {
      this.setup();
    }
  },

  getEnabled() {
    return (
      process.env.NODE_ENV === 'production'
    );
  },

  updateUser(newUserData) {
    if (!this.getEnabled() || !this.ready) return;
    try {
      newUserData?.id ? window.analytics?.identify(newUserData.id, {
        email: newUserData.email,
        first_name: newUserData.organization?.name && `[${newUserData.organization.name}]`,
        last_name: newUserData.name,
      }) : window.analytics?.reset();
    } catch (err) {
      console.error(err);
    }
  },

  trackPageview() {
    if (!this.getEnabled() || !this.ready) return;
    try {
      window.analytics?.pageview();
    } catch (err) {
      console.error(err);
    }
  },

  setup() {
    try {
      // eslint-disable-next-line func-names
      !(function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdp-eu.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";
        analytics.load("32dab7140295a16f1b79");
        analytics.page();
      }})();
      this.ready = true;
    } catch (err) {
      console.error(err);
    }
  },
};
