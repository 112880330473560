import React from "react"
import {
  createTheme,
  createGlobalStyle,
} from "v2/Services/theme"
import {
  CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider,
} from "v2/Lib/mui"

export const ThemeModeContext = React.createContext({ toggleThemeMode: () => {} })

export const LOCAL_STORAGE_THEME_MODE_KEY = "clouderp-theme"

export default function ThemeMode ({ children }) {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = React.useState(
    window.localStorage.getItem(LOCAL_STORAGE_THEME_MODE_KEY) ||
    "light",
  )
  const themeMode = React.useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light"
          window.localStorage.setItem(LOCAL_STORAGE_THEME_MODE_KEY, newMode)
          return newMode
        })
      },
    }),
    [],
  )

  const theme = React.useMemo(
    () => createTheme(mode),
    [mode],
  )
  const globalStyle = React.useMemo(
    () => createGlobalStyle(mode),
    [mode],
  )

  window.theme = theme

  return (
    <ThemeModeContext.Provider value={themeMode}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={globalStyle} />
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeModeContext.Provider>
  )
}
