import Cookies from "js-cookie"
import moment from "moment"
import cronstrue from "cronstrue/i18n"
import "moment/locale/hu"

export default {
  getCurrentLanguage () {
    const availableLanguages = ["hu", "en"]
    const languageCookie = Cookies.get("DJANGO_LANGUAGE")
    const defaultLanguage = availableLanguages[0]
    if (!languageCookie) Cookies.set("DJANGO_LANGUAGE", defaultLanguage)

    return (languageCookie && availableLanguages.includes(languageCookie))
      ? languageCookie
      : defaultLanguage
  },

  displayCron (cron) {
    return cronstrue.toString(
      cron,
      {
        locale: this.getCurrentLanguage(),
        throwExceptionOnParseError: false,
        use24HourTimeFormat: true,
      },
    )
  },

  async init () {
    const currentLanguage = this.getCurrentLanguage()

    moment.locale(currentLanguage, {
      longDateFormat: {
        LT: "H:mm",
        LTS: "H:mm:ss",
        L: "YYYY-MM-DD",
        LL: "YYYY. MMMM D.",
        LLL: "YYYY. MMMM D. H:mm",
        LLLL: "YYYY. MMMM D., dddd H:mm",
      },
    })
    window.moment = moment
    initDjango()
    try {
      let cachedLocale = window.localStorage.getItem("clouderp-locale")
      if (cachedLocale) cachedLocale = JSON.parse(cachedLocale)
      if (
        cachedLocale &&
        cachedLocale.language === currentLanguage &&
        cachedLocale.version === Cookies.get("app_version")
      ) {
        initDjango(cachedLocale.catalog)
        return
      }
      await fetch("/api/1/locale/", {
        credentials: "same-origin",
      }).then(
        async (response) => {
          if (response.status === 200) {
            const { catalog } = await response.json()
            initDjango(catalog)
            window.localStorage.setItem("clouderp-locale", JSON.stringify({
              catalog,
              language: currentLanguage,
              version: Cookies.get("app_version"),
            }))
          } else {
            throw new Error(await response.text())
          }
        },
      )
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },
}

function initDjango (catalog = null) {
  if (catalog === null) {
    window.django = {
      pgettext: (helpText, str) => str,
      ngettext: (singular, plural, count) => (count === 1 ? singular : plural),
      gettext: (str) => str,
      interpolate (fmt, obj, named) {
        if (named) {
          return fmt.replace(/%\(\w+\)s/g, (match) => String(obj[match.slice(2, -2)]))
        }
        return fmt.replace(/%s/g, () => String(obj.shift()))
      },
      pluralidx: (count) => (count === 1 ? 0 : 1),
    }
  } else {
    window.django.gettext = function gettext (msgid) {
      const value = catalog[msgid]
      if (typeof (value) === "undefined") {
        return msgid
      }
      return (typeof (value) === "string") ? value : value[0]
    }

    window.django.ngettext = function ngettext (singular, plural, count) {
      const value = catalog[singular]
      if (typeof (value) === "undefined") {
        return (count === 1) ? singular : plural
      }
      return value.constructor === Array ? value[window.django.pluralidx(count)] : value
    }

    window.django.pgettext = function pgettext (context, msgid) {
      let value = window.django.gettext(`${context}\x04${msgid}`)
      if (value.indexOf("\x04") !== -1) {
        value = msgid
      }
      return value
    }

    window.django.npgettext = function npgettext (context, singular, plural, count) {
      let value = window.django.ngettext(`${context}\x04${singular}`, `${context}\x04${plural}`, count)
      if (value.indexOf("\x04") !== -1) {
        value = window.django.ngettext(singular, plural, count)
      }
      return value
    }
  }
}

export const apexHungarianLocale = {
  name: "hu",
  options: {
    months: [
      "Január",
      "Február",
      "Március",
      "Április",
      "Május",
      "Június",
      "Július",
      "Augusztus",
      "Szeptember",
      "Október",
      "November",
      "December",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Már",
      "Ápr",
      "Máj",
      "Jún",
      "Júl",
      "Aug",
      "Szep",
      "Okt",
      "Nov",
      "Dec",
    ],
    days: [
      "Vasárnap",
      "Hétfő",
      "Kedd",
      "Szerda",
      "Csütörtök",
      "Péntek",
      "Szombat",
    ],
    shortDays: [
      "Vas",
      "Hét",
      "Ke",
      "Sze",
      "Csü",
      "Pén",
      "Szo",
    ],
    toolbar: {
      exportToSVG: "SVG Letöltése",
      exportToPNG: "PNG Letöltése",
      menu: "Menü",
      selection: "Kiválasztás",
      selectionZoom: "Kiválasztás nagyítása",
      zoomIn: "Közelítés",
      zoomOut: "Távolítás",
      pan: "Mozgatás",
      reset: "Nagyítás visszaálltása",
    },
  },
}
