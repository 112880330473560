// import "./wdyr"
import React from "react"
import ReactDOM from "react-dom/client"

import PageLoad from "v2/Components/PageLoad"
import locale from "v2/Services/locale"
import sentry from "v2/Services/sentry"
import beamer from "Services/beamer"
import customerio from "Services/customerio"

export default async function startApp () {
  const container = document.getElementById("root")
  const root = ReactDOM.createRoot(container)
  root.render(
    <PageLoad />,
  )
  await Promise.all([locale.init(), sentry.init(), beamer.init(), customerio.init()])

  // Only import any component after locale.init(), to make sure window.django is set
  const App = await import(/* webpackChunkName: 'v2App', webpackPreload: true */ "./App")
  const AppComponent = App.default

  root.render(
    <AppComponent />,
  )
}

startApp()
