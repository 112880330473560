import React from "react"
import ReactLottie from "react-lottie"

export default function Lottie (props) {
  const {
    animationData,
    animationOptions: animationOptionsProps,
    ...rest
  } = props

  const animRef = React.useRef(null)
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    ...animationOptionsProps,
  }

  React.useEffect(() => {
    animRef.current.anim.setSubframe(false)
  }, [])

  return (
    <ReactLottie
      options={animationOptions}
      ref={animRef}
      {...rest}
    />
  )
}
